import http from '@/utils/http';

/**
 * 产品标签管理
 */
export default {
  getList: {
    p: 'post,/product/tag/getlist',
    r: (data) => http({ url: '/product/tag/getlist', method: 'post', data })
  },
  add: {
    p: 'post,/product/tag/add',
    r: (data) => http({ url: '/product/tag/add', method: 'post', data })
  },
  update: {
    p: 'post,/product/tag/update',
    r: (data) => http({ url: '/product/tag/update', method: 'post', data })
  },
  del: {
    p: 'post,/product/tag/delete',
    r: (data) => http({ url: '/product/tag/delete', method: 'post', data })
  },
  getOne: {
    p: 'post,/product/tag/getOne',
    r: (data) => http({ url: '/product/tag/getone', method: 'post', data })
  }
};
